import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Nav from '../components/Nav'
import Footer from '../components/Footer'

import './foundation.css'
import '../styles/app.scss'
import '../styles/index.scss'
import '../styles/motion.min.css'
import './layout.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'title', content: 'Who do you want to kick out?' },
            { name: 'description', content: 'GetUp is gearing up to take on hard right MPs in the upcoming federal election. And we need your help deciding who to target.' },
            { property: 'og:title', content: 'Who do you want to kick out?' },
            { property: 'og:description', content: 'GetUp is gearing up to take on hard right MPs in the upcoming federal election. And we need your help deciding who to target.' },
            { property: 'og:image', content: 'https://d68ej2dhhub09.cloudfront.net/image_19614_full.jpg' },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1.0, viewport-fit=cover',
            },
          ]}
        >
          <html lang="en" />
          <link rel="stylesheet" href="https://use.typekit.net/fxq2vrx.css" />
          <link
            href="https://fonts.googleapis.com/css?family=Inconsolata:400,700"
            rel="stylesheet"
          />
        </Helmet>
        <Nav />
        <div className="container">{children}</div>
        <Footer />
      </React.Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
