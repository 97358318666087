import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import LoadingAnimation from '../components/LoadingAnimation.jsx'

export default class DonateButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      donateLink:
        'https://www.getup.org.au/campaigns/federal-election-2019/vote-them-out-donation/vote-them-out-b0bfb29b-0f9c-4061-a85d-091a3a2db62f',
    }
  }

  componentDidMount() {
    function getURLParam(sParam) {
      var sPageURL = window.location.search.substring(1)
      var sURLVariables = sPageURL.split('&')
      var keyAndValue, indexOfEquals, key, value
      for (var i = 0; i < sURLVariables.length; i++) {
        keyAndValue = sURLVariables[i]
        indexOfEquals = keyAndValue.indexOf('=')
        if (indexOfEquals > -1) {
          key = keyAndValue.substring(0, indexOfEquals)
          value = keyAndValue.substring(indexOfEquals + 1).replace(/\//g, '')
          if (key === sParam) {
            return value
          }
        }
      }
    }
    let token = getURLParam('t')
    let crewToken = getURLParam('s')

    let crewLink =
      'https://www.getup.org.au/campaigns/federal-election-2019/vote-them-out-donation/vote-them-out-crew'
    let regularLink =
      'https://www.getup.org.au/campaigns/federal-election-2019/vote-them-out-donation/vote-them-out-b0bfb29b-0f9c-4061-a85d-091a3a2db62f'
    let majorDonorLink =
      'https://www.getup.org.au/campaigns/federal-election-2019/vote-them-out-donation/vote-them-out-md'

    if (crewToken === 'crew') {
      if (token) crewLink = crewLink + '?t=' + token
      this.setState({ donateLink: crewLink })
    } else if (crewToken === 'md') {
      if (token) majorDonorLink = majorDonorLink + '?t=' + token
      this.setState({ donateLink: majorDonorLink })
    } else {
      if (token) regularLink = regularLink + '?t=' + token
      this.setState({ donateLink: regularLink })
    }
  }
  render() {
    return (
      <Popup
        trigger={
          <button className={this.props.large ? 'btn btn-large' : 'btn'}>
            {' '}
            {this.props.buttonText}
          </button>
        }
        modal
        closeOnDocumentClick
      >
        {close => (
          <div className="modal">
            <a className="close" onClick={close}>
              &times;
            </a>
            <iframe src={this.state.donateLink} />
            <LoadingAnimation />
          </div>
        )}
      </Popup>
    )
  }
}
