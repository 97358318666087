import React, { Component } from 'react'
import { Link } from 'gatsby'
import Logo from '../images/getup-logo.svg'
import '../styles/nav.scss'
import Popup from 'reactjs-popup'
import '../styles/popup.scss'
import LoadingAnimation from '../components/LoadingAnimation.jsx'
import DonateButton from './DonateButton'

export default class Nav extends Component {
  render() {
    return (
      <nav>
        <div className="row">
          <div className="small-12 columns">
            <div className="nav-container">
              <a className="logo">
                <img src={Logo} />
              </a>
              <DonateButton buttonText="Donate" />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
